*,
*::before,
*::after
{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 62.5%;/*1rem = 10px*/
}

:root
{
  --text-font-size: 1.6rem;
  --primary-color: #131415;
  --secondary-color: #fca311;
  --tertiary-color: #e5e5e5;
  --primary-text-color: black;
  --secondary-text-color: white;
}

.container
{
  --container-width: 90%;
  margin: auto;
  width: var(--container-width);
}

.donja-margina-potomci
{
  --margin-bottom-size: 2rem;
}

.donja-margina-potomci > *
{
  margin-bottom: var(--margin-bottom-size);
}

img
{
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.rdw-editor-toolbar img
{
  display: initial;
  height: initial;
  width: initial;
  object-fit: none;
}

.rdw-image-modal-upload-option-label img
{
  object-fit: cover;
}

span, b
{
  font-size: inherit;
}

ul, li, a, p
{
  font-size: var(--text-font-size);
  color: var(--primary-text-color);
}

ul
{
  list-style: none;
}

button
{
  cursor: pointer;
}

a
{
  text-decoration: none;
}

h1
{
  font-size: calc(var(--text-font-size) * 2);
}

h2
{
  font-size: calc(var(--text-font-size) * 1.75);
}

h3
{
  font-size: calc(var(--text-font-size) * 1.75);
}

button
{
  border: none;
  font-size: var(--text-font-size);
}

.title
{
  font-size: calc(2 * var(--text-font-size));
  color: var(--primary-text-color);
  text-align: center;
}

.title--secondary
{
  font-size: calc(1.2 * var(--text-font-size));
}
/*============ NAVIGATION ==========*/
.navigation
{
  /* box-shadow: 0px 2px 10px black; */
  --primary-text-color: white;
  padding: 2rem 0;
  background-color: var(--primary-color);
}

.navigation__link
{
  --text-font-size: 1.5rem;
  font-weight: normal;
}

input[type="checkbox"][name="navigacijaHamburger"]
{
  display: none;
}

.dropdown__kid.korisnicki-dropdown
{
  width: 13rem;
  left: initial;
  right: 0;
  background-color: white;
  --primary-text-color: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.navigation__items
{
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.dropdown__kid .navigation__item
{
  margin: 0.5rem auto;
}

.navigation__link
{
  display: block;
  padding: 1rem;
  text-align: center;
}

.navigation__link:hover
{
  background-color: teal;
  color: white;
}

.navigacija__hamburger
{
  display: none;
  font-size: var(--text-font-size);
}
/*============ HOME ==========*/
.home
{
  --text-font-size: 2rem;
  height: 100vh;
  color: black;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.377), rgba(255, 255, 255, 0.658)), url("./images/SnowyMountain.jpg");
  background-size: cover;
  background-position: center;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home__title
{
  color: white;
  text-shadow: 0.05em 0.05em 0.3em black;
  font-size: 10rem;
  text-align: center;
}
/*=========== KONTROLE ===========*/
.kontrola
{
  font-size: var(--text-font-size);
  color: var(--primary-text-color);
  border-radius: 4px;
  border: 1px solid var(--primary-color);
  width: 100%;
  padding: 0.5rem;
  margin: 1rem 0;
  height: 4.2rem;
}

/*========= NAPRAVI NALOG ===========*/
.napravi-nalog
{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  /* background-color: #14213dd6; */
  background-size: cover;
  background-position: center;
}
/*========== PRIJAVI SE ===========*/
.prijavi-se
{
  width: 50%;
  margin: 10rem auto;
}

/*======= FORMA ========*/
.forma
{
  margin: 5rem auto;
  padding: 2rem;
  width: 100%;
  max-width: 50rem;
  background-color: whitesmoke;
  border-radius: 12px;
}

.forma__dugme
{
  width: 100%;
  font-size: var(--text-font-size);
  cursor: pointer;
  border-radius: 8px;
  padding: 1rem;
  color: white;
  background-color: var(--primary-color);
  border: none;
}
/*========= PADAJUCI MENI (DROPDOWN) =========*/
.dropdown__parent
{
  position: relative;
}

.dropdown__parent::after
{
  content: "";
  /* position: absolute;
  bottom: 10%; */
  display: inline-block;
  height: 0;
  width: 0;

  --triangle-height: 0.5rem;
  --triangle-color: white;
  border-top: var(--triangle-height) solid var(--triangle-color);
  border-bottom: var(--triangle-height) solid transparent;
  border-left: var(--triangle-height) solid transparent;
  border-right: var(--triangle-height) solid transparent;
}

/*======>> AKTIVIRAN*/
/* .dropdown__parent:hover
{
  background-color: greenyellow;
} */

.dropdown__parent:hover .dropdown__kid
{
  transform: scaleY(100%);
}

.dropdown__parent:hover::after
{
  /* border-top-color: transparent;
  border-bottom-color: var(--triangle-color); */
  transform: translateY(calc(-1 * var(--triangle-height))) rotate(180deg);
}

/*===>> DROPDOWN KID <<===*/
.dropdown__kid
{
  position: absolute;
  left: 0;
  z-index: 2;
  background-color: gray;
  transform: scaleY(0);
  width: 100%;
}

.drzac-slike
{
  position: relative;
  height: 20rem;
}

.drzac-slike img
{
  object-fit: cover;
}
/*========= OBJAVA ==========*/
/* .objava *
{
  margin: 1rem auto;
} */
.objava.container
{
  --container-width: 100%;
  max-width: 65rem;
}

.objava__spisak
{
  --broj-elemenata-u-vrsti: 3;
  /* --velicina-objave: 32%; */
  --minimalna-velicina-objave: 20rem;
  --razmak-medju-elementima: 1rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.objava__spisak > *
{
  width: calc((100% / var(--broj-elemenata-u-vrsti)) - var(--razmak-medju-elementima));
}

.objava__pregled
{
  /* width: var(--velicina-objave); */
  /* min-width: var(--minimalna-velicina-objave); */
  border: 1px solid gray;
  border-radius: 5px;
  margin-bottom: var(--razmak-medju-elementima);
}

.objava__pregled
{
  margin-left: calc(var(--razmak-medju-elementima) / 2);
  margin-right: calc(var(--razmak-medju-elementima) / 2);
  margin-bottom: 2rem;
}

.objava__naslov
{
  font-weight: 700;
  font-size: 2rem;
}

.objava__kratak-opis
{
  color: black;
}

.objava__autor
{
  --text-font-size: 1.5rem;
}

.objava__procitaj-celu
{
  --text-font-size: 3rem;
}

.objava__procitaj-celu:hover
{
  color: #fca311;
}

.objava__tagovi
{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.objava__tag
{
  display: inline-block;
  text-decoration: none;
  padding: 0.5rem 1.5rem;
  color: white;
  background-color: #14213d;
}

.objava__holder
{
  margin: 4rem auto;
  display: flex;
  gap: 5rem;
}

.objava__tekst,
.objava__unos-komentara
{
  /* --najmanja-visina-polja-za-unos: 20rem; */
  resize: none;
  overflow-y: scroll;
  /* min-height: var(--najmanja-visina-polja-za-unos); */
  height: auto;
}

.objava__unos-komentara
{
  --najmanja-visina-polja-za-unos: 10rem;
}

/* .objava__cela.container
{
  --container-width: 50%;
} */

.objava__kontejner-dugmica
{
  display: flex;
  justify-content: space-between;
}

.objava__potvrdi-novi-tag
{
  padding: 1rem 3rem;
  background-color: green;
  color: white;
  border: none;
  font-size: var(--text-font-size);
}

.objava__dugme
{
  font-size: 2rem;
  padding: 1rem 3rem;
  background-color: var(--primary-color);
  color: white;
  border: none;
}

.objava__dugme-dodaj-pasus
{
  display: block;
  width: 100%;
  padding: 1rem;
}

.objava__idi-nazad
{
  content: "\2190";
  position: absolute;
  top: 2%;
  left: 5%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 8rem;
}

.objava__reakcije
{
  display: flex;
  justify-content: space-around;
}

.objava__reakcija-holder i
{
  font-size: 3rem;
  margin-right: 0.5rem;
}

.objava__datum-pisanja
{
  font-size: 1.5rem;
  font-weight: 700;
}

.strelica,
.strelica::before,
.strelica::after
{
  z-index: 1;
  display: block;
  height: var(--arrow-height);
  width: var(--arrow-width);
  background-color: #fca311;
}

.strelica
{
  position: relative;
}

.strelica::before,
.strelica::after
{
  position: absolute;
  content: "";  
}

.strelica::before
{
  transform: translateY(-30px) translateX(-8px) rotate(-45deg);
}

.strelica::after
{
  transform: translateY(30px) translateX(-8px) rotate(45deg);
}


/*=========== PREDLOZI =============*/
.predlog-kontejner
{
  font-size: var(--text-font-size);
  padding: 1rem;
  border-bottom: 2px solid orangered;
  background-color: wheat;
}

.predlog-kontejner:hover
{
  background-color: orangered;
  color: white;
  cursor: pointer;
}

/*================= PORUKE =================*/
.poruka
{
  border: 1px solid black;
}

.poruka.kompresovana .poruka__telo
{
  transform: scaleY(0);
  position: absolute;
}

.poruka__zaglavlje
{
  display: flex;
  justify-content: space-between;
}

.poruka__naslov
{
  font-size: 2rem;
}

.poruka__posiljalac
{
  font-size: 1.5rem;
}

.poruka__datum-pisanja
{
  font-size: 1.5rem;
}

.poruka__otvori
{
  padding: 0.5rem;
}

.poruka.neprocitana
{
  background-color: #fca311;
}

.lista-predloga
{
  border: 1px solid orangered;
  position: absolute;
  z-index: 1;
  width: 100%;
}

.kontejner-unosa-sa-predlozima
{
  position: relative;
}

.poruka__spisak-zaglavlje
{
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
}

.poruka__spisak-filter,
.poruka__spisak-filter option
{
  font-size: var(--text-font-size);
}

/*============ ZABRANA ============*/
.zabrana
{
  text-align: center;
}

.zabrana__paragraf,
.zabrana__razlog
{
  font-size: 3rem;
}

.zabrana__razlog
{
  color: red;
}

.paginacija__lista
{
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.paginacija__dugme
{
  padding: 1rem;
  border-radius: 5px;
  cursor: pointer;
}

.paginacija__dugme:hover
{
  outline: 2px solid olivedrab;
}

input[type="radio"][name="paginacijaDugme"]:checked + .paginacija__dugme
{
  background-color: orange;
  color: white;
}

.dve-kolone
{
  display: flex;
  gap: 5rem;
}

.dugme-dodaj-sliku
{
  display: block;
  font-size: 1.6rem;
  border: 1px solid green;
  cursor: pointer;
  text-align: center;
}

/*=========== PREPORUKE ==============*/
.objava__holder .preporuke
{
  max-width: 44rem;
}

.preporuke__holder
{
  /* max-width: 32rem; */
  display: flex;
  flex-wrap: wrap;
}

.preporuke__holder > * 
{
  width: calc(100% / var(--broj-preporuka-u-vrsti, 1) - var(--razmak-izmedju-objava, 5px));
  margin-left: calc(var(--razmak-izmedju-objava, 5px) / 2);
  margin-right: calc(var(--razmak-izmedju-objava, 5px) / 2);
}

input[type="radio"][name="aktivnost-dugme"]
{
  display: none;
}

input[type="radio"][name="aktivnost-dugme"]:checked + label
{
  background-color: greenyellow;
  color: white;
}

input[type="radio"][name="aktivnost-dugme"] ~ label
{
  display: block;
  font-size: 2.5rem;
  border: 2px solid orangered;
  padding: 1rem;
}

input[type="radio"][name="aktivnost-dugme"] ~ label:hover
{
  cursor: pointer;
}

label + section
{
  display: none;
}

input[type="radio"][name="aktivnost-dugme"]:checked + label + section
{
  display: block;
}

  
select[name="objavePoDatumu"]
{
  font-size: 2rem;
}

/*================ AKTIVNOST ====================*/
.aktivnost__dugme
{
  display: block;
  padding: 1rem;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid orangered;
  width: 100%;
  text-align: center;
}

.aktivnost__dugme:hover,
.aktivnost__dugme:focus,
.aktivnost__dugme:active
{
  color: orangered;
  background-color: #14213d;
  cursor: pointer;
}

input[type="radio"][name="aktivnost-dugme"] + section
{
  position: absolute;
  transform: scaleY(0);
}

input[name="aktivnost-dugme"]:checked + section
{
  position: initial;
  transform: scaleY(1);
}


.aktivnost__drzac-tabova
{
  display: flex;
}

.aktivnost__drzac-tabova > li
{
  flex-grow: 1;
}

/*================ ADMIN ============================*/
.admin__obrisi-objavu
{
  display: inline-block;
  padding: 1rem 2rem;
  font-weight: 700;
  color: white;
  background-color: red;
}

/*================ MEDIA QUERIES ============================*/
@media screen and (max-width: 700px) 
{
  .navigation
  {
    --primary-text-color: black;
    position: relative;
  }

  .navigation__items
  {
    position: absolute;
    z-index: 1;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    max-width: 27rem;
  }  

  .navigacija__hamburger
  {
    display: block;
    max-width: 10rem;
    background-color: #fca311;
    font-size: 2rem;
    text-transform: uppercase;
    font-weight: 700;
    padding: 0.5rem 1rem;
  }

  .objava__holder
  {
    flex-direction: column;
  }

  /*========== CHECBOX HAMBURGRER LABEL ==========*/
  input[type="checkbox"][name="navigacijaHamburger"] ~ .navigation__items
  {
    display: none;
  }

  input[type="checkbox"][name="navigacijaHamburger"]:checked + label
  {
    content: "Gasi navigaciju";
  }

  input[type="checkbox"][name="navigacijaHamburger"]:checked ~ .navigation__items
  {
    display: flex;
    border: 1px solid black;
  }

  .objava__spisak
  {
    --broj-elemenata-u-vrsti: 1;
  }

  .navigation__item
  {
    display: block;
  }

  .navigation__item.dropdown__parent
  {
    order: -1;
  }

  .navigation__item.dropdown__parent.navigation-active .dropdown__kid.korisnicki-dropdown
  {
    display: block;
    position: initial;
  }
  

  .dropdown__kid.korisnicki-dropdown
  {
    /* position: initial; */
    display: none;
  }
}

/*=============== KORISNICKA STRANICA ==================*/
.link-za-korisnicku-stranicu:hover
{
  cursor: pointer;
  color: firebrick;/*jer nije fora da koristim filter*/
}

/*=============== PRETRAGA PO TAGOVIMA ==================*/
.ponudjeni-tagovi
{
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1;
}

/* .ponudjeni-tagovi-drzac:focus .ponudjeni-tagovi,
.ponudjeni-tagovi-drzac:focus-within .ponudjeni-tagovi
{
  display: block;
} */

.ponudjeni-tagovi-drzac.active .ponudjeni-tagovi
{
  display: block;
}

.ponudjeni-tag
{
  padding: 1rem;
  border: 1px solid gray;
  background-color: white;
}

#unosZaTag.active + .ponudjeni-tagovi
{
  display: block;
}

.ponudjeni-tag:hover
{
  cursor: pointer;
}

.ponudjeni-tag .dugme-odabir-taga
{
  display: none;
}

.radio-labela
{
  cursor: pointer;
  padding: 1rem;
  margin-right: 1rem;
}

.ponudjeni-tag .dugme-odabir-taga:checked + .radio-labela .radio-simbol
{
  color: #fca311;
}

.fa-solid
{
  font-size: 2.5rem;
}

.ponudjeni-tagovi-drzac
{
  position: relative;
}

.odabrani-tagovi
{
  display: flex;
  flex-wrap: wrap;
}

/*============== KORISNICKA STRANICA ==============*/
.korisnik__naslovna-slika-holder
{
  max-height: 30rem;
  overflow: hidden;
}

.korisnik__zaglavlje
{
  position: relative;
  margin-top: 8rem;
}

.korisnik__naslov
{
  margin-top: 2rem;
}

.korisnik__profilna-slika-holder
{
  --precnik-kruga: 150px;
  height: var(--precnik-kruga);
  width: var(--precnik-kruga);
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 2%;
  margin-bottom: 3rem;
}

.uredi__objavu
{
  display: block;
  padding: 1rem 2rem;
  background-color: black;
  color: white;
}

p + p
{
  margin: 10px auto;
}

/*========== REACT WYSIWYG EDITOR CLASS ==========*/
.rdw-editor-wrapper
{
  border: 2px solid black;
  min-height: 30rem;
}

.active-route
{
  background-color: teal;
  color: white !important;
}

.korisnik-u-adminu
{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  max-width: 32rem;
}

.korisnicko-ime
{
  margin-right: 1rem;
  font-weight: 100;
  font-size: 2rem;
}

.dugme-broj-neprocitanih-poruka
{
  border-radius: 50%;
  --circle-radius: 16px;
  height: var(--circle-radius);
  width: var(--circle-radius);
  margin-right: 5px;
  background-color: white;
  color: black;
  display: inline-block;
  text-align: center;
}

.objava__odnos-reakcija
{
  display: flex;
  gap: 1rem;
}

/* img
{
  height: 100% !important;
  width: 100% !important;
} */


/*============== WYSIWYG OVERLOADED STYLES ===============*/
.rdw-image-right {
  float: right;
}

.rdw-image-alignment {
  display: inline !important;
  }
  
  .rdw-image-center {
  display: flex !important;
  justify-content: center;
  }
  
  .rdw-image-right {
  float: right !important;
  }
  
  .rdw-image-left {
  float: left !important;
  }

  span
  {
    word-break: break-all;
  }
/*====>> za objavu, posto figure se prevede u div possle, a umesto ffloat koristtti
  text-align
<<===*/
  div[style*="text-align: right"]
  {
    float: right;
  }

  div[style*="text-align: left"]
  {
    float: left;
  }


  .poruka__telo{
    background-color: white;
  }